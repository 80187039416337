.ant-btn[disabled]:hover {
  cursor: progress;
}
/* 
.centerQuizButtomText.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  transform: translate(50%, -50%);
  position: absolute;
  top: 50%;
  right: 45%;
} 
.ant-btn > span + .anticon {
  transform: translate(0, -3px);
}
*/

/*
  APPO 'Cita confirmada' --exxon-blue-70
  CANCE 'Cancelado' --dark-red
  CLOS 'Entregado'  --blue
  CREAT 'Confimarción del cliente pendiente' --orange
  DIAG 'En diagnósito' --black-50
  PEND 'Confirmación Pendiente' --red
  PROG 'Reparando'  --black-40
  READY 'Terminado' --exxon-blue-60
  RECEP 'Recepción' --black-70
  */

/* ----- Buttons ----- */
.ant-btn.--custom {
  border-radius: 8px !important;
  padding: 5px 10px;
  height: auto;
  font-size: 1rem;
}
.ant-btn.ant-btn-sm.--custom {
  font-size: 0.9rem;
}
.ant-btn.ant-btn-lg.--custom {
  font-size: 1.1rem;
}

/* Defaults  */
/* Positive */
.ant-btn.--custom.ant-btn-default {
  background: var(--blue) !important;
  border-color: var(--blue) !important;
  color: #fff !important;
  /* box-shadow: 2px 2px 5px 0px rgba( !important0, 0, 0, 0.5);
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5); */
}
.ant-btn.--custom.ant-btn-default:not([disabled]):active,
.ant-btn.--custom.ant-btn-default:not([disabled]):focus,
.ant-btn.--custom.ant-btn-default:not([disabled]):hover {
  background: var(--blue-dark);
  border-color: var(--blue-dark);
}

/* negative */
.ant-btn.--custom.--negative.ant-btn-default {
  background: var(--error-color) !important;
  border-color: var(--error-color) !important;
}
.ant-btn.--custom.--negative.ant-btn-default:not([disabled]):active,
.ant-btn.--custom.--negative.ant-btn-default:not([disabled]):focus,
.ant-btn.--custom.--negative.ant-btn-default:not([disabled]):hover {
  background: var(--red-dark);
  border-color: var(--red-dark);
}

/* Orange */
.ant-btn.--custom.--orange.ant-btn-default {
  background: var(--orange) !important;
  border-color: var(--orange) !important;
}
.ant-btn.--custom.--orange.ant-btn-default:not([disabled]):active,
.ant-btn.--custom.--orange.ant-btn-default:not([disabled]):focus,
.ant-btn.--custom.--orange.ant-btn-default:not([disabled]):hover {
  background: var(--orange-dark) !important;
  border-color: var(--orange-dark) !important;
}

/* Blue exxon 60 */
.ant-btn.--custom.--xblue-60.ant-btn-default {
  background: var(--exxon-blue-60);
  border-color: var(--exxon-blue-60);
}
.ant-btn.--custom.--xblue-60.ant-btn-default:not([disabled]):active,
.ant-btn.--custom.--xblue-60.ant-btn-default:not([disabled]):focus,
.ant-btn.--custom.--xblue-60.ant-btn-default:not([disabled]):hover {
  background: var(--exxon-blue-60);
  border-color: var(--exxon-blue-60);
  opacity: 0.9;
}

/* Blue exxon 70 */
.ant-btn.--custom.--xblue-70.ant-btn-default {
  background: var(--exxon-blue-70);
  border-color: var(--exxon-blue-70);
}
.ant-btn.--custom.--xblue-70.ant-btn-default:not([disabled]):active,
.ant-btn.--custom.--xblue-70.ant-btn-default:not([disabled]):focus,
.ant-btn.--custom.--xblue-70.ant-btn-default:not([disabled]):hover {
  background: var(--exxon-blue-70);
  border-color: var(--exxon-blue-70);
  opacity: 0.9;
}

/* Black 40 */
.ant-btn.--custom.--black-40.ant-btn-default {
  background: var(--black-40);
  border-color: var(--black-40);
}
.ant-btn.--custom.--black-40.ant-btn-default:not([disabled]):active,
.ant-btn.--custom.--black-40.ant-btn-default:not([disabled]):focus,
.ant-btn.--custom.--black-40.ant-btn-default:not([disabled]):hover {
  background: var(--black-40);
  border-color: var(--black-40);
  opacity: 0.9;
}

/* Black 50 */
.ant-btn.--custom.--black-50.ant-btn-default {
  background: var(--black-50);
  border-color: var(--black-50);
}
.ant-btn.--custom.--black-50.ant-btn-default:not([disabled]):active,
.ant-btn.--custom.--black-50.ant-btn-default:not([disabled]):focus,
.ant-btn.--custom.--black-50.ant-btn-default:not([disabled]):hover {
  background: var(--black-50);
  border-color: var(--black-50);
  opacity: 0.9;
}

/* Black 70 */
.ant-btn.--custom.--black-70.ant-btn-default {
  background: var(--black-70);
  border-color: var(--black-70);
}
.ant-btn.--custom.--black-70.ant-btn-default:not([disabled]):active,
.ant-btn.--custom.--black-70.ant-btn-default:not([disabled]):focus,
.ant-btn.--custom.--black-70.ant-btn-default:not([disabled]):hover {
  background: var(--black-70);
  border-color: var(--black-70);
  opacity: 0.9;
}

/* Red dark */
.ant-btn.--custom.--red-dark.ant-btn-default {
  background: var(--red-dark);
  border-color: var(--red-dark);
}
.ant-btn.--custom.--red-dark.ant-btn-default:not([disabled]):active,
.ant-btn.--custom.--red-dark.ant-btn-default:not([disabled]):focus,
.ant-btn.--custom.--red-dark.ant-btn-default:not([disabled]):hover {
  background: var(--red-dark);
  border-color: var(--red-dark);
  opacity: 0.9;
}

/* whatsapp */
.ant-btn.--custom.--whatsapp.ant-btn-default {
  background: #075e54 !important;
  border-color: #075e54 !important;
  color: #fff;
}
.ant-btn.--custom.--whatsapp.ant-btn-default:not([disabled]):active,
.ant-btn.--custom.--whatsapp.ant-btn-default:not([disabled]):focus,
.ant-btn.--custom.--whatsapp.ant-btn-default:not([disabled]):hover {
  background: #075e54 !important;
  border-color: #075e54 !important;
  opacity: 0.8;
  color: #fff !important;
}

/* whatsapp */
.ant-btn.--custom.--lime.ant-btn-default {
  background: #37d16a !important;
  border-color: #37d16a !important;
  color: #fff;
}
.ant-btn.--custom.--lime.ant-btn-default:not([disabled]):active,
.ant-btn.--custom.--lime.ant-btn-default:not([disabled]):focus,
.ant-btn.--custom.--lime.ant-btn-default:not([disabled]):hover {
  background: #37d16a !important;
  border-color: #37d16a !important;
  opacity: 0.8;
  color: #fff !important;
}
.ant-btn.--custom.--lime.ant-btn-default[disabled] {
  background-color: var(--black-10) !important;
  color: var(--black-40) !important;
  border-color: var(--black-10) !important;
}

.ant-btn.--custom.ant-btn-default[disabled],
.ant-btn.--custom.ant-btn-default[disabled]:hover,
.ant-btn.--custom.ant-btn-default[disabled]:focus,
.ant-btn.--custom.ant-btn-default[disabled]:active {
  background-color: var(--black-10) !important;
  color: var(--black-40) !important;
  border-color: var(--black-10) !important;
}

.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  background-color: var(--black-10) !important;
  color: var(--black-40) !important;
  border-color: var(--black-10) !important;
  cursor: not-allowed !important;
}

/* Ghost */
/* Positive */
.ant-btn.ant-btn-ghost.--custom {
  color: var(--blue);
  border-color: var(--blue);
}
.ant-btn.--custom.ant-btn-ghost:not([disabled]):active,
.ant-btn.--custom.ant-btn-ghost:not([disabled]):focus,
.ant-btn.--custom.ant-btn-ghost:not([disabled]):hover {
  color: var(--blue-dark);
  border-color: var(--blue-dark);
}

/* negative */
.ant-btn.ant-btn-ghost.--custom.--negative {
  color: var(--red) !important;
  border-color: var(--red) !important;
}
.ant-btn.--custom.--negative.ant-btn-ghost:not([disabled]):active,
.ant-btn.--custom.--negative.ant-btn-ghost:not([disabled]):focus,
.ant-btn.--custom.--negative.ant-btn-ghost:not([disabled]):hover {
  color: var(--red-dark);
  border-color: var(--red-dark);
}

/* orange */
.ant-btn.ant-btn-ghost.--custom.--orange {
  color: var(--orange);
  border-color: var(--orange);
}
.ant-btn.--custom.--orange.ant-btn-ghost:not([disabled]):active,
.ant-btn.--custom.--orange.ant-btn-ghost:not([disabled]):focus,
.ant-btn.--custom.--orange.ant-btn-ghost:not([disabled]):hover {
  color: var(--orange-dark);
  border-color: var(--orange-dark);
}

/* Blue Exxon 60 */
.ant-btn.ant-btn-ghost.--custom.--xblue-60 {
  color: var(--exxon-blue-60);
  border-color: var(--exxon-blue-60);
}
.ant-btn.--custom.--xblue-60.ant-btn-ghost:not([disabled]):active,
.ant-btn.--custom.--xblue-60.ant-btn-ghost:not([disabled]):focus,
.ant-btn.--custom.--xblue-60.ant-btn-ghost:not([disabled]):hover {
  color: var(--exxon-blue-60);
  border-color: var(--exxon-blue-60);
  opacity: 0.8;
}

/* Blue Exxon 70 */
.ant-btn.ant-btn-ghost.--custom.--xblue-70 {
  color: var(--exxon-blue-70);
  border-color: var(--exxon-blue-70);
}
.ant-btn.--custom.--xblue-70.ant-btn-ghost:not([disabled]):active,
.ant-btn.--custom.--xblue-70.ant-btn-ghost:not([disabled]):focus,
.ant-btn.--custom.--xblue-70.ant-btn-ghost:not([disabled]):hover {
  color: var(--exxon-blue-70);
  border-color: var(--exxon-blue-70);
  opacity: 0.8;
}

/* Black 40 */
.ant-btn.ant-btn-ghost.--custom.--black-40 {
  color: var(--black-40);
  border-color: var(--black-40);
}
.ant-btn.--custom.--black-40.ant-btn-ghost:not([disabled]):active,
.ant-btn.--custom.--black-40.ant-btn-ghost:not([disabled]):focus,
.ant-btn.--custom.--black-40.ant-btn-ghost:not([disabled]):hover {
  color: var(--black-40);
  border-color: var(--black-40);
  opacity: 0.8;
}

/* Black 50 */
.ant-btn.ant-btn-ghost.--custom.--black-50 {
  color: var(--black-50);
  border-color: var(--black-50);
}
.ant-btn.--custom.--black-50.ant-btn-ghost:not([disabled]):active,
.ant-btn.--custom.--black-50.ant-btn-ghost:not([disabled]):focus,
.ant-btn.--custom.--black-50.ant-btn-ghost:not([disabled]):hover {
  color: var(--black-50);
  border-color: var(--black-50);
  opacity: 0.8;
}

/* Black 70 */
.ant-btn.ant-btn-ghost.--custom.--black-70 {
  color: var(--black-70);
  border-color: var(--black-70);
}
.ant-btn.--custom.--black-70.ant-btn-ghost:not([disabled]):active,
.ant-btn.--custom.--black-70.ant-btn-ghost:not([disabled]):focus,
.ant-btn.--custom.--black-70.ant-btn-ghost:not([disabled]):hover {
  color: var(--black-70);
  border-color: var(--black-70);
  opacity: 0.8;
}

/* Red Dark */
.ant-btn.ant-btn-ghost.--custom.--red-dark {
  color: var(--red-dark);
  border-color: var(--red-dark);
}
.ant-btn.--custom.--red-dark.ant-btn-ghost:not([disabled]):active,
.ant-btn.--custom.--red-dark.ant-btn-ghost:not([disabled]):focus,
.ant-btn.--custom.--red-dark.ant-btn-ghost:not([disabled]):hover {
  color: var(--red-dark);
  border-color: var(--red-dark);
  opacity: 0.8;
}

/* Link */
/* positive */
.ant-btn.ant-btn-link.--custom {
  color: var(--blue);
  background-color: unset;
  border: none;
  font-weight: 600;
}
.ant-btn.--custom.ant-btn-link:not([disabled]):active,
.ant-btn.--custom.ant-btn-link:not([disabled]):focus,
.ant-btn.--custom.ant-btn-link:not([disabled]):hover {
  color: var(--blue-dark);
}

/* negative */
.ant-btn.ant-btn-link.--custom.--negative {
  color: var(--red);
  font-weight: 600;
}
.ant-btn.--custom.--negative.ant-btn-link:not([disabled]):active,
.ant-btn.--custom.--negative.ant-btn-link:not([disabled]):focus,
.ant-btn.--custom.--negative.ant-btn-link:not([disabled]):hover {
  color: var(--red-dark);
}

/* orange */
.ant-btn.ant-btn-link.--custom.--orange {
  color: var(--orange);
  font-weight: 600;
}
.ant-btn.--custom.--orange.ant-btn-link:not([disabled]):active,
.ant-btn.--custom.--orange.ant-btn-link:not([disabled]):focus,
.ant-btn.--custom.--orange.ant-btn-link:not([disabled]):hover {
  color: var(--orange-dark);
}

/* Exxon Blue 60 */
.ant-btn.ant-btn-link.--custom.--xblue-60 {
  color: var(--exxon-blue-60);
  font-weight: 600;
}
.ant-btn.--custom.--xblue-60.ant-btn-link:not([disabled]):active,
.ant-btn.--custom.--xblue-60.ant-btn-link:not([disabled]):focus,
.ant-btn.--custom.--xblue-60.ant-btn-link:not([disabled]):hover {
  color: var(--exxon-blue-60);
  opacity: 0.7;
}

/* Exxon Blue 70 */
.ant-btn.ant-btn-link.--custom.--xblue-70 {
  color: var(--exxon-blue-70);
  font-weight: 600;
}
.ant-btn.--custom.--xblue-70.ant-btn-link:not([disabled]):active,
.ant-btn.--custom.--xblue-70.ant-btn-link:not([disabled]):focus,
.ant-btn.--custom.--xblue-70.ant-btn-link:not([disabled]):hover {
  color: var(--exxon-blue-70);
  opacity: 0.7;
}

/* Black 60 */
.ant-btn.ant-btn-link.--custom.--black-40 {
  color: var(--black-40);
  font-weight: 600;
}
.ant-btn.--custom.--black-40.ant-btn-link:not([disabled]):active,
.ant-btn.--custom.--black-40.ant-btn-link:not([disabled]):focus,
.ant-btn.--custom.--black-40.ant-btn-link:not([disabled]):hover {
  color: var(--black-40);
  opacity: 0.7;
}

/* Black 60 */
.ant-btn.ant-btn-link.--custom.--black-50 {
  color: var(--black-50);
  font-weight: 600;
}
.ant-btn.--custom.--black-50.ant-btn-link:not([disabled]):active,
.ant-btn.--custom.--black-50.ant-btn-link:not([disabled]):focus,
.ant-btn.--custom.--black-50.ant-btn-link:not([disabled]):hover {
  color: var(--black-50);
  opacity: 0.7;
}

/* Black 70 */
.ant-btn.ant-btn-link.--custom.--black-70 {
  color: var(--black-70);
  font-weight: 600;
}
.ant-btn.--custom.--black-70.ant-btn-link:not([disabled]):active,
.ant-btn.--custom.--black-70.ant-btn-link:not([disabled]):focus,
.ant-btn.--custom.--black-70.ant-btn-link:not([disabled]):hover {
  color: var(--black-70);
  opacity: 0.7;
}

/* Black 70 */
.ant-btn.ant-btn-link.--custom.--red-dark {
  color: var(--red-dark);
  font-weight: 600;
}
.ant-btn.--custom.--black-70.ant-btn-link:not([disabled]):active,
.ant-btn.--custom.--red-dark.ant-btn-link:not([disabled]):focus,
.ant-btn.--custom.--red-dark.ant-btn-link:not([disabled]):hover {
  color: var(--red-dark);
  opacity: 0.7;
}

.button_blue {
  background-color: var(--primary-color) !important;
  color: white !important;
  border-radius: 8px !important;
  font-weight: 400;
}

.button_blue:hover {
  background-color: var(--blue-dark) !important;
  color: white !important;
}
.button_blue.disabled {
  background: var(--black-15) !important;
  border: 1px solid var(--black-50) !important;
  color: var(--black-50) !important;
}
.button_red {
  background-color: var(--red) !important;
  color: white !important;
  border-radius: 8px !important;
  font-weight: 400;
}

.button_red:hover {
  background-color: var(--red-dark) !important;
  color: white !important;
}

/* ----- /Buttons ----- */

.ant-btn.--underlined,
.ant-btn.--underlined span,
.ath-btn.--underlined:hover,
.ath-btn.--underlined:hover span {
  text-decoration: underline !important;
}

.ant-btn.--bordered {
  border: 2px solid currentColor !important;
}

.ant-btn.--custom.--white {
  color: #fff !important;
}

.ant-form.no-padding {
  padding: 0 !important;
}
