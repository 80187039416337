.ant-radio-inner::after {
  background-color: var(--mariner-color) !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--primary-color) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--mariner-color);
  border-color: var(--mariner-color);
}
/* ----- Checkbox ----- */
/**
  * Variants
  * positive|default -> .--custom
  * negative -> .--custom.--negative
  * orange -> .--custom.--orange
  * exxon blue 70 -> .--custom.--xblue-70
  * exxon blue 60 -> .--custom.--xblue-60
  * 
  */
/* general */
.ant-checkbox-wrapper:not(
    .service-check,
    #packageServiceSelectionForm
      > div.ant-row.ant-row-center
      > div
      > div
      > div.ant-collapse.ant-collapse-icon-position-end.ant-collapse-ghost.package-selection-collapse
      > div
      > div
      > div.ant-collapse-extra
      > label
  ) {
  font-size: var(--font-size-base);
}

.ant-checkbox-wrapper.--custom .ant-checkbox .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom
  .ant-checkbox.ant-checkbox-disabled
  .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  border-radius: 0;
}
.ant-checkbox-wrapper.--custom
  .ant-checkbox-checked
  .ant-checkbox-inner::after {
  transform: rotate(45deg) scale(1.5) translate(-35%, -50%);
}
.ant-checkbox-wrapper.--custom.--inline {
  display: flex;
  align-items: center;
}

/* positive | default */
.ant-checkbox-wrapper.--custom .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--blue);
  border-color: var(--blue-dark);
  display: flex;
  align-items: center;
}

.ant-checkbox-wrapper.--custom:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom .ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--blue-dark);
}

/* negative */
.ant-checkbox-wrapper.--custom.--negative
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--red);
  border-color: var(--red-dark);
}
.ant-checkbox-wrapper.--custom.--negative:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--negative
  .ant-checkbox:hover
  .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--negative
  .ant-checkbox-input:focus
  + .ant-checkbox-inner {
  border-color: var(--red-dark);
}

/* orange */
.ant-checkbox-wrapper.--custom.--orange
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--orange);
  border-color: var(--orange);
}
.ant-checkbox-wrapper.--custom.--orange:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--orange .ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--orange
  .ant-checkbox-input:focus
  + .ant-checkbox-inner {
  border-color: var(--orange);
}

/* exxon blue 70 */
.ant-checkbox-wrapper.--custom.--xblue-70
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--exxon-blue-70);
  border-color: var(--exxon-blue-70);
}
.ant-checkbox-wrapper.--custom.--xblue-70:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--xblue-70
  .ant-checkbox:hover
  .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--xblue-70
  .ant-checkbox-input:focus
  + .ant-checkbox-inner {
  border-color: var(--exxon-blue-70);
}

/* Terminado | READY | exxon blue 60 */
.ant-checkbox-wrapper.--custom.--xblue-60
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--exxon-blue-60);
  border-color: var(--exxon-blue-60);
}
.ant-checkbox-wrapper.--custom.--xblue-60:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--xblue-60
  .ant-checkbox:hover
  .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--xblue-60
  .ant-checkbox-input:focus
  + .ant-checkbox-inner {
  border-color: var(--exxon-blue-60);
}

/* Black 70 */
.ant-checkbox-wrapper.--custom.--black-70
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--black-70);
  border-color: var(--black-70);
}
.ant-checkbox-wrapper.--custom.--black-70:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--black-70
  .ant-checkbox:hover
  .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--black-70
  .ant-checkbox-input:focus
  + .ant-checkbox-inner {
  border-color: var(--black-70);
}

/* Black 50 */
.ant-checkbox-wrapper.--custom.--black-50
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--black-50);
  border-color: var(--black-50);
}
.ant-checkbox-wrapper.--custom.--black-50:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--black-50
  .ant-checkbox:hover
  .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--black-50
  .ant-checkbox-input:focus
  + .ant-checkbox-inner {
  border-color: var(--black-50);
}

/* Black 40 */
.ant-checkbox-wrapper.--custom.--black-40
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--black-40);
  border-color: var(--black-40);
}
.ant-checkbox-wrapper.--custom.--black-40:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--black-40
  .ant-checkbox:hover
  .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--black-40
  .ant-checkbox-input:focus
  + .ant-checkbox-inner {
  border-color: var(--black-40);
}

/* red dark */
.ant-checkbox-wrapper.--custom.--red-dark
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--red);
  border-color: var(--red);
}
.ant-checkbox-wrapper.--custom.--red-dark:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--red-dark
  .ant-checkbox:hover
  .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--red-dark
  .ant-checkbox-input:focus
  + .ant-checkbox-inner {
  border-color: var(--red);
}

/* ------------------------------------------------------------------------------------------ */
/*
  CREAT 'Confimarción del cliente pendiente' --CREAT-color  
  PEND 'Confirmación Pendiente' --PEND-color
  APPO 'Cita confirmada' --APPO-color
  RECEP 'Recepción' --RECEP-color  
  DIAG 'En diagnósito' --DIAG-color
  QUOT 'Cotización' --QUOT-color
  PROG 'Reparando'  --PROG-color
  READY 'Terminado' --READY-color
  CLOS 'Entregado'  --CLOS-color
  CANCE 'Cancelado' --CANCE-color
*/

/* Todos | ALL */
.ant-checkbox-wrapper.--custom.--ALL .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--ALL-color);
  border-color: var(--ALL-color);
}
.ant-checkbox-wrapper.--custom.--ALL:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--ALL .ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--ALL
  .ant-checkbox-input:focus
  + .ant-checkbox-inner {
  border-color: var(--ALL-color);
}

/* Confimarción del cliente pendiente | CREAT */
.ant-checkbox-wrapper.--custom.--CREAT
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--CREAT-color);
  border-color: var(--CREAT-color);
}
.ant-checkbox-wrapper.--custom.--CREAT:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--CREAT .ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--CREAT
  .ant-checkbox-input:focus
  + .ant-checkbox-inner {
  border-color: var(--CREAT-color);
}

/* Confirmación Pendiente | PEND */
.ant-checkbox-wrapper.--custom.--PEND
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--PEND-color);
  border-color: var(--PEND-color);
}
.ant-checkbox-wrapper.--custom.--PEND:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--PEND .ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--PEND
  .ant-checkbox-input:focus
  + .ant-checkbox-inner {
  border-color: var(--PEND-color);
}

/* Cita confirmada | APPO  */
.ant-checkbox-wrapper.--custom.--APPO
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--APPO-color);
  border-color: var(--APPO-color);
}
.ant-checkbox-wrapper.--custom.--APPO:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--APPO .ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--APPO
  .ant-checkbox-input:focus
  + .ant-checkbox-inner {
  border-color: var(--APPO-color);
}

/* Recepción | RECEP */
.ant-checkbox-wrapper.--custom.--RECEP
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--RECEP-color);
  border-color: var(--RECEP-color);
}
.ant-checkbox-wrapper.--custom.--RECEP:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--RECEP .ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--RECEP
  .ant-checkbox-input:focus
  + .ant-checkbox-inner {
  border-color: var(--RECEP-color);
}

/* En diagnósito | DIAG */
.ant-checkbox-wrapper.--custom.--DIAG
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--DIAG-color);
  border-color: var(--DIAG-color);
}
.ant-checkbox-wrapper.--custom.--DIAG:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--DIAG .ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--DIAG
  .ant-checkbox-input:focus
  + .ant-checkbox-inner {
  border-color: var(--DIAG-color);
}

/* Cotizando | QUOT */
.ant-checkbox-wrapper.--custom.--QUOT
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--QUOT-color);
  border-color: var(--QUOT-color);
}
.ant-checkbox-wrapper.--custom.--QUOT:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--QUOT .ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--QUOT
  .ant-checkbox-input:focus
  + .ant-checkbox-inner {
  border-color: var(--QUOT-color);
}

/* Reparando | PROG */
.ant-checkbox-wrapper.--custom.--PROG
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--PROG-color);
  border-color: var(--PROG-color);
}
.ant-checkbox-wrapper.--custom.--PROG:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--PROG .ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--PROG
  .ant-checkbox-input:focus
  + .ant-checkbox-inner {
  border-color: var(--PROG-color);
}

/* Terminado | READY  */
.ant-checkbox-wrapper.--custom.--READY
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--READY-color);
  border-color: var(--READY-color);
}
.ant-checkbox-wrapper.--custom.--READY:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--READY .ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--READY
  .ant-checkbox-input:focus
  + .ant-checkbox-inner {
  border-color: var(--READY-color);
}

/* Entregado | CLOS */
.ant-checkbox-wrapper.--custom.--CLOS
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--CLOS-color);
  border-color: var(--CLOS-color);
}
.ant-checkbox-wrapper.--custom.--CLOS:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--CLOS .ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--CLOS
  .ant-checkbox-input:focus
  + .ant-checkbox-inner {
  border-color: var(--CLOS-color);
}

/* Cancelado | CANCE */
.ant-checkbox-wrapper.--custom.--CANCE
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--CANCE-color);
  border-color: var(--CANCE-color);
}
.ant-checkbox-wrapper.--custom.--CANCE:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--CANCE .ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--custom.--CANCE
  .ant-checkbox-input:focus
  + .ant-checkbox-inner {
  border-color: var(--CANCE-color);
}

/* ------------------------------------------------------------------------------------------ */

/* secondary */
.ant-checkbox-wrapper.--custom.--secondary {
  color: var(--text-color-secondary);
}

/* disabled */
.ant-checkbox-wrapper.--custom
  .ant-checkbox.ant-checkbox-checked.ant-checkbox-disabled
  .ant-checkbox-inner::after {
  border-color: #fff;
}
.ant-checkbox-wrapper.--custom
  .ant-checkbox.ant-checkbox-checked.ant-checkbox-disabled
  .ant-checkbox-inner {
  background-color: var(--black-10);
}

/* ---- Alter ----*/
.ant-checkbox-wrapper.--alter .ant-checkbox .ant-checkbox-inner,
.ant-checkbox-wrapper.--alter
  .ant-checkbox.ant-checkbox-disabled
  .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-color: var(--primary-color);
  border-radius: 2px;
  border-width: 2px;
}
.ant-checkbox-wrapper.--alter
  .ant-checkbox.ant-checkbox-disabled
  .ant-checkbox-inner {
  border-color: rgba(var(--primary-color-rgb), 0.5) !important;
}

.ant-checkbox-wrapper.--alter:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--alter .ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-wrapper.--alter .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--primary-color);
}

.ant-checkbox-wrapper.--alter .ant-checkbox-checked .ant-checkbox-inner::after {
  transform: rotate(45deg) scale(1.2) translate(-45%, -45%);
}
.ant-checkbox-wrapper.--alter.--inline {
  display: flex;
  align-items: center;
}

/* positive | default */
.ant-checkbox-wrapper.--alter .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: var(--blue);
}

/* Orange  */
.ant-checkbox-wrapper.--alter.--orange
  .ant-checkbox-checked
  .ant-checkbox-inner::after {
  border-color: var(--secondary-dark-color);
}
/* ---- Alter ----*/

/* ----- Checkbox ----- */
